@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Suranna&display=swap');

:root {
  --black: #272727;
  --white: #F1F0EF;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24;
  color: var(--black);
  font-size: 5rem;
  cursor: pointer;
  border: 2px solid var(--black);
  border-radius: 50%;
  padding: 0.5rem;
  user-select: none;
  /* margin-left: -1rem; */
}


body {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  /* overflow-x: scroll; */
}

.App {
  /* text-align: center; */
  height: 100%;
  width: 150%;
  background-color: var(--white);
  /* transform: translateX(-50vw); */
  color: var(--black);
  transition: all 0.5s ease-in-out;
}

h1,
h2,
h3 {
  font-family: 'Suranna', serif;
}

h2 {
  font-size: 3rem;
}

#hero,
#nav,
#contentContainer {
  height: calc(var(--vh, 1vh) * 100);
  width: 50vw;
}

#hero {
  border-right: 5px solid var(--black);
  overflow: hidden;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

#hero h1 {
  font-size: 10rem;
}

#hero h2 {
  font-size: 5rem;
}

#menuButton {
  color: var(--white);
  border-color: var(--white);
  visibility: hidden;
  position: absolute;
}

.hideOnDesktop {
  visibility: hidden;
}

#nav {
  padding: 0;
  list-style: none;
  font-family: 'Suranna', serif;
  border-right: 5px solid var(--black);
  position: absolute;
  top: 0;
  left: 50vw;
}

.navItem {
  height: 33.34%;
  width: 100%;
  border-top: 5px solid var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  color: var(--black);
  user-select: none;
  cursor: pointer;
  transition: all 0.25s linear;
}

.navItem:first-child {
  border: none;
}

#contentContainer {
  position: absolute;
  top: 0;
  left: 100vw;
  padding: 3rem;
  overflow-y: scroll;
  /* padding-bottom: 8rem; */
}

#contentContainer h1 {
  font-size: 5rem;
}

#contentContainer h2 {
  margin: 0;
}

#contentContainer h3 {
  margin: 0 0 0.5rem 0;
  font-size: 2rem;
}

#contentContainer p {
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
}

#contentContainer button {
  border-radius: 50px;
  font-family: 'Suranna', serif;
  color: var(--black);
}

.subNav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.navButton {
  padding: 0 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  border: 2px solid var(--black);
}

.contentButton {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  border: 2px solid var(--black);
  margin-bottom: 3rem;
  border-radius: 50px;
  text-align: center;
}

.contentButtonLink {
  text-decoration: none;
  font-family: 'Suranna', serif;
  color: var(--black);
}

.blue:hover {
  background-color: #004AAD;
  color: var(--white);
}

.red,
.blue,
.yellow {
  background-color: white;
  transition: all 0.25s linear;
}

.yellowRev {
  background-color: #FFDE59;
  transition: all 0.25s linear;
}

.red:hover {
  background-color: #FF5757;
}

.yellow:hover {
  background-color: #FFDE59;
}

.yellowRev:hover {
  background-color: white;
}

.position0 {
  transform: unset;
}

.position1 {
  transform: translateX(0vw);
}

.position2 {
  transform: translateX(-50vw);
}

/* MUISC TEACHING TAB */

.musicHeroRow {
  margin: 3rem 0 4rem 0;
}

.coursesImg {
  width: 100%;
  margin: 0 0 1rem 0;
  border: 2px solid var(--black);
  /* padding: 0.25rem; */
}

.iframeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

iframe {
  border: 2px solid var(--black);
  padding: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.courseCollapse {
  width: 207%;
  margin-bottom: 3rem;
}

.card-body {
  border: none;
}

.courseCardBody {
  border: 2px solid var(--black);
  border-radius: 8px;
  padding: 1rem
}

.courseCardBody p {
  margin-bottom: 0.75rem;
}

.moveCollapseLeft {
  margin-left: -106%;
}


/* WEB /////////////////////////////////////// */

.accordion-item {
  border: none;
}

/* ABOUT //////////////// */

.aboutHeroRow {
  margin: 3rem 0;
}



@media only screen and (max-width: 1300px) {

  .material-symbols-outlined {
    transform: scale(0.7);
  }

  #hero h1 {
    font-size: 4rem;
  }

  #hero h2 {
    font-size: 2rem;
  }

  .navItem {
    font-size: 5rem;
  }

  #contentContainer {
    padding: 1rem;
  }

  #contentContainer h1 {
    font-size: 4rem;
  }

  #contentContainer p {
    font-size: 1rem;
  }

  #contentContainer button {
    font-size: 1.5rem;
  }

  .musicHeroRow {
    margin: 3rem 0 0 0;
  }

}

@media only screen and (max-width: 1200px) {
  .courseCollapse {
    width: 100%;
  }

  .moveCollapseLeft {
    margin-left: 0;
  }
}


@media only screen and (max-width: 768px) {

  #hero,
  #nav,
  #contentContainer {
    width: 100vw;
  }

  #hero {
    background-position: center;
  }

  #menuButton {
    visibility: visible;
    position: unset;
  }

  .hideOnDesktop {
    visibility: visible;
  }

  #nav {
    left: 100vw;
  }

  .navItem {
    font-size: 5rem;
    height: 25%;
  }

  #contentContainer {
    left: 200vw;
  }

  .position1 {
    transform: translateX(-100vw);
  }

  .position2 {
    transform: translateX(-200vw);
  }
}